<template>
  <v-card-text class="pa-0">
    <v-toolbar flat color="transparent">
      <v-icon class="grey--text mr-3">mdi-briefcase-clock-outline</v-icon>
      <h2 class="heading font-weight-normal grey--text">{{ $t('smartLink.title') }}</h2>
      <v-spacer></v-spacer>
      <v-tooltip :bottom="true">
        <template v-slot:activator="{ on }">
          <v-badge
            :value="Object.keys(Smartlinks.state.filters).length"
            :content="Object.keys(Smartlinks.state.filters).length"
            overlap
            color="blue lighten-1"
          >
            <v-btn v-on="on" @click="show = !show" depressed small class="ml-2">
              <v-icon>{{ show ? 'mdi-filter-minus-outline' : 'mdi-filter-plus-outline' }}</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>{{
          Object.keys(Smartlinks.state.filters).length
            ? `${Object.keys(Smartlinks.state.filters).length} filters have been applied`
            : 'No filters applied yet'
        }}</span>
      </v-tooltip>
    </v-toolbar>
    <transition name="scale-transition">
      <v-divider v-if="show" class="mx-5"></v-divider>
    </transition>
    <transition name="scale-transition">
      <v-card v-if="show" color="transparent" elevation="0" class="ma-0 pa-0">
        <v-card-text>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6">
                <span class="subtitle-1 font-weight-light">Smartlink ID</span>
                <v-text-field
                  :value="Smartlinks.state.filters._id"
                  @input="refreshFilteredResult('_id', $event)"
                  label="Smartlink ID"
                  clearable
                  flat
                  dense
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-pen"
                ></v-text-field>
                <span class="subtitle-1 font-weight-light">Smartlink Name</span>
                <v-text-field
                  :value="Smartlinks.state.filters.name"
                  @input="refreshFilteredResult('name', $event)"
                  label="Smartlink Name"
                  clearable
                  flat
                  dense
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-pen"
                ></v-text-field>
                <span class="subtitle-1 font-weight-light">Smartlink Status</span>
                <v-autocomplete
                  :value="Smartlinks.state.filters._applicationStatus"
                  :items="SmartlinkStatus"
                  @input="refreshFilteredResult('_applicationStatus', $event)"
                  item-text="name"
                  item-value="_id"
                  label="Smartlink Status"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                >
                  <template v-slot:selection="data">
                    <v-chip small v-bind="data.attrs" :input-value="data.selected" class="ma-1">
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </transition>
  </v-card-text>
</template>
<script>
import { inject, onMounted, reactive, watch, toRef } from '@vue/composition-api';
import _ from 'lodash';

export default {
  name: 'smartlink-filters',
  components: {},
  setup(props, context) {
    const Categories = inject('Categories');
    const Smartlinks = inject('Smartlinks');
    const SmartlinkStatus = [
      { _id: 'Applied', name: 'Applied' },
      { _id: 'Approved', name: 'Approved' },
      { _id: 'Rejected', name: 'Rejected' },
    ];
    const self = reactive({
      show: Object.keys(Smartlinks.state.filters).length,
    });
    const refreshFilteredResult = _.debounce((key, value) => {
      Smartlinks.actions.applyFilter({ key: key, value: value });
      context.emit('resetPagination');
    }, 500);
    const removeOnList = (list, value) => {
      const index = _.indexOf(list, value);
      if (index >= 0) {
        list.splice(index, 1);
      }
      return list;
    };
    onMounted(() => {
      Categories.actions.loadItems();
    });
    return {
      ...self,
      Categories,
      Smartlinks,
      SmartlinkStatus,
      refreshFilteredResult,
      removeOnList,
    };
  },
};
</script>
